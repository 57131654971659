import { useLocation } from 'react-router-dom';
import { CoreBox, CoreTypography } from '@youscience/khaleesi';
import { Divider } from '@mui/material';

import { ROUTES } from '@constants/ROUTES';

import { usePlannerSidebar } from '@hooks/usePlannerSidebar';

import { useEducationPlannerStore } from '@store/educationPlannerStore';

import ChevronLeftIcon from '@images/chevron-left.svg?react';
import ListIcon from '@images/list.svg?react';
import ProgressIcon from '@images/progress.svg?react';
import RemovedTasksIcon from '@images/removed-tasks.svg?react';

import {
  sxStyles,
  StyledSidebarWrap,
  StyledToggle,
  StyledSidebarList,
  StyledSidebarItem,
  StyledRemovedTaskBtn,
  StyledBottomSidebarActions,
} from './PlannerSidebar.styles';

export const PlannerSidebar = () => {
  const location = useLocation();

  const { isSidebarOpen, isShowRemovedTasks, handleSidebarClick, toggleSidebar, handleOpenTaskArchivePage } =
    usePlannerSidebar();

  const { isTaskArchivePage } = useEducationPlannerStore((state) => state.educationPlannerData);

  return (
    <StyledSidebarWrap data-testid="plannerSidebar" variant="permanent" open={isSidebarOpen}>
      <CoreBox sx={sxStyles.toggleWrap}>
        <StyledToggle isSidebarOpen={isSidebarOpen} onClick={toggleSidebar}>
          <ChevronLeftIcon data-testid="closeIcon" />
        </StyledToggle>
      </CoreBox>

      <StyledSidebarList data-testid="sidebarList" isSidebarOpen={isSidebarOpen}>
        <CoreBox sx={sxStyles.topSidebarActions}>
          {isSidebarOpen ? (
            <CoreTypography sx={sxStyles.sidebarTitle} variant="subtitle4">
              Planning
            </CoreTypography>
          ) : null}

          <StyledSidebarItem
            to={`${ROUTES.EDUCATION_PLANNER}${location.hash}`}
            isSelected={location.pathname === ROUTES.EDUCATION_PLANNER && !isTaskArchivePage}
            isSidebarOpen={isSidebarOpen}
            onClick={handleSidebarClick}
          >
            <ListIcon />
            {isSidebarOpen ? <CoreTypography variant="body2">College planning</CoreTypography> : null}
          </StyledSidebarItem>

          <StyledSidebarItem
            to={`${ROUTES.EDUCATION_PLANNER_APPLICATIONS}${location.hash}`}
            isSelected={location.pathname === ROUTES.EDUCATION_PLANNER_APPLICATIONS}
            isSidebarOpen={isSidebarOpen}
            onClick={handleSidebarClick}
          >
            <ProgressIcon />

            {isSidebarOpen ? <CoreTypography variant="body2">College applications</CoreTypography> : null}
          </StyledSidebarItem>
        </CoreBox>

        <Divider />

        {isShowRemovedTasks && location.pathname === ROUTES.EDUCATION_PLANNER ? (
          <StyledBottomSidebarActions>
            <StyledRemovedTaskBtn
              isActive={isTaskArchivePage}
              isSidebarOpen={isSidebarOpen}
              onClick={handleOpenTaskArchivePage}
            >
              <RemovedTasksIcon />

              {isSidebarOpen && <CoreTypography variant="body2">Removed tasks</CoreTypography>}
            </StyledRemovedTaskBtn>
          </StyledBottomSidebarActions>
        ) : null}
      </StyledSidebarList>
    </StyledSidebarWrap>
  );
};
