import { Suspense, lazy } from 'react';
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { Layout } from '@components/Layout';

import { ROUTES } from '@constants/ROUTES';

const Home = lazy(() => import('@pages/Home'));
const MajorList = lazy(() => import('@pages/MajorList'));
const MajorDetails = lazy(() => import('@pages/MajorDetails'));
const FavoriteMajorList = lazy(() => import('@pages/FavoriteMajorList'));
const Scholarships = lazy(() => import('@pages/Scholarships'));
const ScholarshipDetails = lazy(() => import('@pages/ScholarshipDetails'));
const SchoolList = lazy(() => import('@pages/SchoolList'));
const FavoriteSchoolList = lazy(() => import('@pages/FavoriteSchoolList'));
const SchoolDetails = lazy(() => import('@pages/SchoolDetails'));
const EducationPlanner = lazy(() => import('@pages/EducationPlanner'));
const EducationPlannerSchools = lazy(() => import('@pages/EducationPlannerApplications'));
const RecommenderSpace = lazy(() => import('@pages/RecommenderSpace'));
const StudentDetailsPage = lazy(() => import('@pages/StudentDetailsPage'));
const RecommenderProfile = lazy(() => import('@pages/RecommenderSpace/components/RecommenderProfile'));
const SchoolProfile = lazy(() => import('@pages/RecommenderSpace/components/RecommenderSchoolProfile'));
const OrganizationDashboard = lazy(() => import('@pages/RecommenderSpace/components/OrganizationDashboard'));

export function AppRoutes() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />}>
        <Route
          path={ROUTES.ROOT}
          element={
            <Suspense>
              <Home />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.SCHOOL_DETAILS}
          path={ROUTES.SCHOOL_DETAILS}
          element={
            <Suspense>
              <SchoolDetails />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.SCHOOLS}
          path={ROUTES.SCHOOLS}
          element={
            <Suspense>
              <SchoolList />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.SCHOOLS_BY_MAJOR}
          path={ROUTES.SCHOOLS_BY_MAJOR}
          element={
            <Suspense>
              <SchoolList />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.FAVORITE_SCHOOLS}
          path={ROUTES.FAVORITE_SCHOOLS}
          element={
            <Suspense>
              <FavoriteSchoolList />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.SCHOOL_DETAILS}
          path={ROUTES.SCHOOL_DETAILS}
          element={
            <Suspense>
              <SchoolDetails />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.EDUCATION_PLANNER}
          path={ROUTES.EDUCATION_PLANNER}
          element={
            <Suspense>
              <EducationPlanner />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.EDUCATION_PLANNER_APPLICATIONS}
          path={ROUTES.EDUCATION_PLANNER_APPLICATIONS}
          element={
            <Suspense>
              <EducationPlannerSchools />
            </Suspense>
          }
        />
        ,
        <Route
          path={ROUTES.MAJORS}
          element={
            <Suspense>
              <MajorList />
            </Suspense>
          }
        />
        ,
        <Route
          path={ROUTES.MAJOR_DETAILS}
          element={
            <Suspense>
              <MajorDetails />
            </Suspense>
          }
        />
        ,
        <Route
          path={ROUTES.FAVORITE_MAJORS}
          element={
            <Suspense>
              <FavoriteMajorList />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.SCHOLARSHIPS}
          path={ROUTES.SCHOLARSHIPS}
          element={
            <Suspense>
              <Scholarships />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.SCHOLARSHIP_DETAILS}
          path={ROUTES.SCHOLARSHIP_DETAILS}
          element={
            <Suspense>
              <ScholarshipDetails />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.RECOMMENDER_SPACE}
          path={ROUTES.RECOMMENDER_SPACE}
          element={
            <Suspense>
              <RecommenderSpace />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.RECOMMENDER_PROFILE}
          path={ROUTES.RECOMMENDER_PROFILE}
          element={
            <Suspense>
              <RecommenderProfile />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.SCHOOL_PROFILE}
          path={ROUTES.SCHOOL_PROFILE}
          element={
            <Suspense>
              <SchoolProfile />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.ORGANIZATION_DASHBOARD}
          path={ROUTES.ORGANIZATION_DASHBOARD}
          element={
            <Suspense>
              <OrganizationDashboard />
            </Suspense>
          }
        />
        ,
        <Route
          key={ROUTES.STUDENT_DETAILS}
          path={ROUTES.STUDENT_DETAILS}
          element={
            <Suspense>
              <StudentDetailsPage />
            </Suspense>
          }
        />
        ,
        <Route path="*" element={<Navigate to={ROUTES.ROOT} />} />
      </Route>,
    ),
  );
}
