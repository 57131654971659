import { createWithEqualityFn } from 'zustand/traditional';

import { FormState, PersonalDetailsFields } from '@constants/commonApp/types';

import {
  CertificationsFields,
  SchoolDetailsTeacherFields,
} from '@pages/RecommenderSpace/components/RecommenderProfile/types';

import {
  ClassRankFields,
  GPAFields,
  SchoolDetailsCounselorFields,
  SchoolProfileFields,
  TranscriptsFields,
} from '@pages/RecommenderSpace/components/RecommenderSchoolProfile/types';

type SectionNames =
  | 'certifications'
  | 'personalDetails'
  | 'schoolDetailsCounselor'
  | 'schoolProfile'
  | 'classRank'
  | 'gpa'
  | 'transcripts'
  | 'schoolDetailsTeacher';

export enum SectionName {
  Certifications = 'certifications',
  PersonalDetails = 'personalDetails',
  SchoolDetailsCounselor = 'schoolDetailsCounselor',
  SchoolProfile = 'schoolProfile',
  ClassRank = 'classRank',
  GPA = 'gpa',
  Transcripts = 'transcripts',
  SchoolDetailsTeacher = 'schoolDetailsTeacher',
}
interface RecommenderProfileStore {
  initialState: {
    personalDetails: PersonalDetailsFields | null;
    certifications: CertificationsFields | null;
    schoolDetailsCounselor: SchoolDetailsCounselorFields | null;
    schoolProfile: SchoolProfileFields | null;
    classRank: ClassRankFields | null;
    gpa: GPAFields | null;
    transcripts: TranscriptsFields | null;
    schoolDetailsTeacher: SchoolDetailsTeacherFields | null;
  };

  formState: {
    personalDetails: FormState<PersonalDetailsFields>;
    certifications: FormState<CertificationsFields>;
    schoolDetailsCounselor: FormState<SchoolDetailsCounselorFields>;
    schoolProfile: FormState<SchoolProfileFields>;
    classRank: FormState<ClassRankFields>;
    gpa: FormState<GPAFields>;
    transcripts: FormState<TranscriptsFields>;
    schoolDetailsTeacher: FormState<SchoolDetailsTeacherFields>;
  };

  setSectionFormState: <T>(formStateName: SectionNames, formState: FormState<T>) => void;
  setInitialData: <T>(formStateName: SectionNames, initialData: T) => void;
}

export const initialFormState = {
  data: {} as any,
  hasChanged: false,
  isFormValid: true,
  isSaved: false,
  errors: {},
};

const initialStoreState = {
  initialState: {} as Record<SectionNames, any>,
  formState: {} as Record<SectionNames, FormState<any>>,
} as RecommenderProfileStore;

Object.values(SectionName).forEach((sectionName) => {
  const typedSectionName = sectionName as SectionNames;
  initialStoreState.initialState[typedSectionName] = null;
  initialStoreState.formState[typedSectionName] = initialFormState;
});

export const useRecommenderProfileStore = createWithEqualityFn<RecommenderProfileStore>()(
  (set, get) => ({
    ...initialStoreState,

    setSectionFormState: <T>(formStateName: SectionNames, formState: T) => {
      set((prevState) => {
        const currentFormState = prevState.formState[formStateName];
        return {
          formState: {
            ...prevState.formState,
            [formStateName]: {
              ...currentFormState,
              ...formState,
            },
          },
        };
      });
    },

    setInitialData: <T>(formStateName: SectionNames, initialData: T) => {
      const formState = {
        data: {
          ...initialData,
        },
        hasChanged: false,
        isFormValid: true,
        errors: {},
      };

      set({
        initialState: {
          ...get().initialState,
          [formStateName]: { ...initialData },
        },
        formState: {
          ...get().formState,
          [formStateName]: { ...formState },
        },
      });
    },
  }),
  Object.is,
);
