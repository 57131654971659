import { useState, ChangeEvent, FocusEvent, useMemo } from 'react';

import { HELPER_TEXT } from '@constants/optIn';
import { CoreFormControl } from '@youscience/khaleesi';
import { TextField } from '@mui/material';

interface InputMaskProps {
  name: string;
  label: string;
  value?: string;
  isRequired?: boolean;
  helperText?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SEPARATOR = '-';
const PREFIX = '+1 ';
const REGEX = /\+1 \d{0,4}(-\d{0,3}(-\d{0,4})?)?/;
const FORMATTED_PHONE_NUMBER_LENGTH = 15;

export const InputMask = (props: InputMaskProps) => {
  const { name, label, value = '', isRequired, helperText = HELPER_TEXT.phoneRequired, onChange } = props;

  // for case when value has format => 484-414-5367
  const preparedValueWithoutPrefix = value && `${PREFIX}${value.slice(0, 3)}-${value.slice(4, 7)}-${value.slice(8)}`;

  // for case when value has format => +14844145367
  const preparedValueWithPrefix = value && `${PREFIX}${value.slice(2, 5)}-${value.slice(5, 8)}-${value.slice(8)}`;

  const formattedValue = value.includes('+1') ? preparedValueWithPrefix : preparedValueWithoutPrefix;
  const initialValue = value.match(REGEX) ? value : formattedValue;

  const [inputMaskValue, setInputMaskValue] = useState(initialValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.slice(3).replace(/[-\d]/g, '')) {
      return null;
    }

    const [value = PREFIX] = inputValue.match(REGEX) || [];

    let newValue = value;

    if (!inputValue) {
      newValue = PREFIX;
    }

    if (value.length === 3 && inputValue.length > 3) {
      newValue = `${PREFIX}${inputValue.slice(2)}`;
    }

    if (value.length === 7 && value.charAt(inputValue.length - 1) !== SEPARATOR) {
      newValue = `${value.slice(0, 6)}-${value.slice(6)}`;
    }

    if (value.length === 7 && value.charAt(inputValue.length - 1) === SEPARATOR) {
      newValue = `${value.slice(0, 6)}`;
    }

    if (inputValue.length === 11 && value.charAt(inputValue.length - 1) !== SEPARATOR) {
      newValue = `${inputValue.slice(0, 10)}-${inputValue.slice(10)}`;
    }

    if (value.length === 11 && value.charAt(inputValue.length - 1) === SEPARATOR) {
      newValue = `${value.slice(0, 10)}`;
    }

    if (onChange) {
      onChange({
        target: {
          value: newValue,
          name,
        },
      } as ChangeEvent<HTMLInputElement>);
    }

    setInputMaskValue(newValue);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const initialValue = inputValue === PREFIX ? '' : inputValue;

    if (onChange) {
      onChange({
        target: {
          value: initialValue,
          name,
        },
      } as ChangeEvent<HTMLInputElement>);
    }

    setInputMaskValue(initialValue);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const initialValue = !inputValue.length ? PREFIX : inputValue;

    if (onChange) {
      onChange({
        target: {
          value: initialValue,
          name,
        },
      } as ChangeEvent<HTMLInputElement>);
    }

    setInputMaskValue(initialValue);
  };

  const isError = useMemo(() => inputMaskValue.length < FORMATTED_PHONE_NUMBER_LENGTH, [inputMaskValue]);

  return (
    <CoreFormControl fullWidth>
      <TextField
        data-testid="inputMaskTextField"
        label={label}
        value={inputMaskValue}
        required={isRequired}
        error={isError}
        helperText={isError && helperText}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </CoreFormControl>
  );
};
