// adding a comma to separate the number into digits and add currency. like 10000 => $10,000
export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

// adding a comma to separate the number into digits. like 10000 => 10,000
export const separateDigitsByComma = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const getMonthName = (monthNumber: number) => {
  const date = new Date();

  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'short',
  });
};

export const readableDate = (date: Date) => {
  const dateArray = date.toDateString().split(' ');

  return `${dateArray[1]} ${dateArray[2]}, ${dateArray[3]}`;
};

export const formatDateWithFallback = (date: string | undefined | null | Date, fallback = '') => {
  if (!date) {
    return fallback;
  }

  const formattedDate = new Date(date);

  return formattedDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};
