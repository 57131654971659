import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  CoreTypography,
  CoreDialog,
  CoreDialogTitle,
  CoreDialogContent,
  CoreBox,
  CoreFormGroup,
  CoreFormControlLabel,
  CoreCheckbox,
  CoreSwitch,
} from '@youscience/khaleesi';

import { InitialModalsStatus } from '@interfaces/optIn';

import { useOptInStore } from '@store/optInStore';
import { useUserPreferencesStore } from '@store/userPreferencesStore';

import { TenantSharingSchool } from '@interfaces/selectSchool';

import ActionButton from '@components/ActionButton';

import { sxStyles, StyledAlert, StyledDialogFooter, StyledSchoolCounter } from './AllSchoolConnection.styles';

interface AllSchoolConnectionProps {
  isOpenAllSchoolConnection: boolean;
  setActiveModal: (field?: keyof InitialModalsStatus) => void;
}

export const AllSchoolConnection = (props: AllSchoolConnectionProps) => {
  const { isOpenAllSchoolConnection, setActiveModal } = props;

  const { userPreferences, schoolFavorites } = useUserPreferencesStore((state) => state);

  const updateAllSchoolsTenantSharing = useOptInStore((state) => state.updateAllSchoolsTenantSharing);
  const setAllSchoolsTenantSharing = useOptInStore((state) => state.setAllSchoolsTenantSharing);
  const allSchoolsTenantSharing = useOptInStore((state) => state.allSchoolsTenantSharing);

  const [isNotToBeAskedToShare, setIsNotToBeAskedToShare] = useState(userPreferences.isNotToBeAskedToShare ?? false);

  useEffect(() => {
    const currentTenantSharing: Partial<TenantSharingSchool>[] =
      schoolFavorites?.map((favorite) => {
        const schoolIndex = userPreferences.tenantSharing?.findIndex((school) => school.collegeId === favorite.id);

        let currentlySharing = false;

        if (userPreferences.tenantSharing && schoolIndex !== undefined && schoolIndex > -1) {
          currentlySharing = userPreferences.tenantSharing[schoolIndex].currentlySharing;
        }

        return {
          collegeId: favorite.id,
          collegeName: favorite.school.directory.name,
          currentlySharing,
        };
      }) ?? [];

    void setAllSchoolsTenantSharing(currentTenantSharing);
  }, [schoolFavorites]);

  const selectedSchoolCount = useMemo(
    () => allSchoolsTenantSharing?.filter((school) => school.currentlySharing).length,
    [allSchoolsTenantSharing],
  );

  const isAllSchoolsSelected = useMemo(
    () => allSchoolsTenantSharing?.every((school) => school.currentlySharing),
    [allSchoolsTenantSharing],
  );

  const isSomeSchoolSelected = useMemo(
    () => allSchoolsTenantSharing?.some((school) => school.currentlySharing),
    [allSchoolsTenantSharing],
  );

  const updateAllSharingStatus = (status: boolean) => {
    const updatedSchools = allSchoolsTenantSharing?.map((tenantSharing) => ({
      ...tenantSharing,
      currentlySharing: status,
    }));

    setAllSchoolsTenantSharing(updatedSchools);
  };

  const handleParentCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const status = allSchoolsTenantSharing?.some((school) => school.currentlySharing) ? false : event.target.checked;

    updateAllSharingStatus(status);
  };

  const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedSchools = [...allSchoolsTenantSharing];

    const schoolIndex = updatedSchools.findIndex((school) => school.collegeId === +event.target.value);

    if (schoolIndex > -1) {
      updatedSchools[schoolIndex].currentlySharing = event.target.checked;
    } else {
      updatedSchools.push({
        collegeId: +event.target.value,
        currentlySharing: event.target.checked,
      });
    }

    setAllSchoolsTenantSharing(updatedSchools);
  };

  const handleChangeAskToShare = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setIsNotToBeAskedToShare(checked);

    if (checked) {
      updateAllSharingStatus(false);
    }
  };

  const handleCloseAllSchoolConnection = () => {
    void setAllSchoolsTenantSharing([]);

    setActiveModal();
  };

  const handleSaveAllSchoolConnection = () => {
    updateAllSchoolsTenantSharing(isNotToBeAskedToShare);

    setActiveModal();
  };

  const handleOpenShareInfo = () => {
    setActiveModal('shareInfo');
  };

  const handleOpenContactForm = () => {
    setActiveModal('optInContactForm');
  };

  return (
    <CoreDialog sx={sxStyles.dialogWrap} open={isOpenAllSchoolConnection} scroll="paper">
      <CoreDialogTitle sx={sxStyles.title}>
        <CoreTypography component="div" variant="h3">
          Connect with favorite schools
        </CoreTypography>
      </CoreDialogTitle>

      <CoreDialogContent sx={sxStyles.content}>
        <CoreTypography>
          Would you like us to share your information with the schools selected below so they can contact you about
          their programs?
        </CoreTypography>

        <StyledAlert data-testid="infoMessage" severity="info">
          Please note that we will notify you once we have shared your information with schools, and that sharing your
          information with schools does not guarantee they will contact you. Remember to always double-check the college
          admission website for the most up-to-date information on application deadlines, campus visits, and financial
          aid.
        </StyledAlert>

        <CoreFormGroup data-testid="schoolList" sx={sxStyles.schoolList}>
          <CoreFormControlLabel
            sx={sxStyles.listTitle}
            label={<CoreTypography variant="subtitle4">School name</CoreTypography>}
            disabled={isNotToBeAskedToShare}
            control={
              <CoreCheckbox
                sx={sxStyles.checkBox}
                checked={isAllSchoolsSelected}
                indeterminate={!isAllSchoolsSelected && isSomeSchoolSelected}
                onChange={handleParentCheckBoxChange}
              />
            }
          />

          <CoreBox sx={sxStyles.schoolListWrap}>
            {allSchoolsTenantSharing?.map((tenantSharing) => {
              return (
                <CoreFormControlLabel
                  key={tenantSharing.collegeId}
                  sx={sxStyles.listItem}
                  label={<CoreTypography variant="body2">{tenantSharing.collegeName}</CoreTypography>}
                  disabled={isNotToBeAskedToShare}
                  control={
                    <CoreCheckbox
                      sx={sxStyles.checkBox}
                      value={tenantSharing.collegeId}
                      checked={tenantSharing.currentlySharing}
                      onChange={handleCheckBoxChange}
                    />
                  }
                />
              );
            })}
          </CoreBox>
        </CoreFormGroup>

        <CoreFormControlLabel
          sx={{ alignItems: 'center' }}
          label={
            <CoreTypography variant="body2">
              I don&apos;t want to be connected with any schools at this time.
            </CoreTypography>
          }
          control={
            <CoreSwitch
              data-testid="isNotToBeAskedToShare"
              checked={isNotToBeAskedToShare}
              onChange={handleChangeAskToShare}
            />
          }
        />

        <CoreTypography data-testid="shareInfoText" sx={sxStyles.shareInfo} component="div" variant="caption">
          To see a list of the information we may share,{' '}
          <CoreTypography data-testid="shareInfoBtn" variant="caption" onClick={handleOpenShareInfo}>
            click here
          </CoreTypography>
          . Participating schools may compensate YouScience for this service, but there is no cost to you. You can opt
          out of sharing at any time by visiting your profile menu.
        </CoreTypography>

        <CoreTypography variant="caption">
          By clicking the Save button, I agree to share my information with the colleges I have selected.
        </CoreTypography>
      </CoreDialogContent>

      <StyledDialogFooter>
        <CoreBox sx={sxStyles.leftActions}>
          <StyledSchoolCounter>
            <CoreTypography variant="h6">{`${selectedSchoolCount} of ${schoolFavorites?.length} selected`}</CoreTypography>
          </StyledSchoolCounter>

          <ActionButton variant="text" onClick={handleOpenContactForm}>
            Edit my contact information
          </ActionButton>
        </CoreBox>

        <CoreBox sx={sxStyles.rightActions}>
          <ActionButton size="large" variant="outlined" data-testid="close" onClick={handleCloseAllSchoolConnection}>
            Close
          </ActionButton>

          <ActionButton size="large" data-testid="save" onClick={handleSaveAllSchoolConnection}>
            Save
          </ActionButton>
        </CoreBox>
      </StyledDialogFooter>
    </CoreDialog>
  );
};
