import { CoreTypography, CoreDialog } from '@youscience/khaleesi';

import { useSchoolSelectionStore } from '@store/schoolSelectionStore';
import { useHomePageStore } from '@store/homePageStore';

import DialogFooter from '@components/DialogFooter';

import { sxStyles, StyledDialogContent } from './VerificationMessage.styles';

interface VerificationMessageProps {
  isOpenVerificationMessage: boolean;
  onCloseVerificationMessage: () => void;
}

export const VerificationMessage = (props: VerificationMessageProps) => {
  const { isOpenVerificationMessage, onCloseVerificationMessage } = props;

  const { cardInfo } = useHomePageStore((state) => state.landingPageSchoolData);

  const deleteSchoolSelection = useSchoolSelectionStore((state) => state.deleteSchoolSelection);
  const grantAccessToYSProfile = useSchoolSelectionStore((state) => state.grantAccessToYSProfile);
  const getLandingPageData = useHomePageStore((state) => state.getLandingPageData);

  const handleDeleteSchool = async () => {
    const requests = [grantAccessToYSProfile(cardInfo, false)];

    if (deleteSchoolSelection) {
      requests.push(deleteSchoolSelection(cardInfo.id));
    }

    await Promise.all(requests);

    await getLandingPageData();

    onCloseVerificationMessage();
  };

  return cardInfo ? (
    <CoreDialog sx={sxStyles.wrap} open={isOpenVerificationMessage}>
      <StyledDialogContent>
        <CoreTypography sx={sxStyles.title} variant="h3">
          Are you sure?
        </CoreTypography>

        <CoreTypography>
          {/* eslint-disable max-len */}
          {`If you change your in-platform status to unenrolled from ${cardInfo.name}, the system will redirect you to explore all colleges and programs.`}
        </CoreTypography>
      </StyledDialogContent>

      <DialogFooter
        goBackButtonTitle="Cancel"
        goNextButtonTitle="Confirm"
        goNextActionButtonTitle="Confirming"
        onGoBack={onCloseVerificationMessage}
        onGoNext={handleDeleteSchool}
      />
    </CoreDialog>
  ) : null;
};
